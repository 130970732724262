import { Avatar, Menu, Popover } from "antd";
import { memo, useState } from "react";

import "./index.scss";

const userMenuOptions = ({
  logout,
  user,
  handleNavigateClick
}) => {
  return (
    <div className="user-popover">
      <div data-qa="ms-userName-section" className="username-section">
        <div className="userName"> {`Mobile: ${user?.phone}`}</div>
      </div>
      <div data-qa="ms-userName-section" className="username-section">
        <div className="userName"> {`Email: ${user?.email}`}</div>
      </div>
      <div data-qa="ms-userName-section" className="history-section">
        <div className="userName" onClick={handleNavigateClick}>Previous Uploads</div>
      </div>
      <Menu
        items={[]}
      // [{
      //   label: (
      //     <Link to="#" onClick={() => { }}>
      //       <LogoutOutlined />
      //       Logout
      //     </Link>
      //   ),
      //   key: "logout",
      // }]
      />
    </div>
  );
};

const UserInfo = ({ logout, user, navigateToPrevUploads }) => {
  const [menuVisibility, setMenuVisibility] = useState(false);
  const handleVisibleChange = () => {
    setMenuVisibility(!menuVisibility);
  };

  const handleNavigateClick = () => {
    setMenuVisibility(false);
    navigateToPrevUploads();
  };

  return (
    <div>
      <Popover
        placement="bottomRight"
        content={userMenuOptions({
          logout,
          user,
          handleNavigateClick
        })}
        trigger="click"
        open={menuVisibility}
        onOpenChange={handleVisibleChange}
        overlayClassName="user-info-popover"
      >
        <Avatar src={require("assets/images/user-logo.png")} alt="" />
      </Popover>
    </div>
  );
};

export default memo(UserInfo);
