import axios from "axios";
import { displayNotification, resetLoader, setLoaderDisplay } from "../app/appAction";
import { isNotEmpty } from "./helper";
import { resetUser } from "components/UserLogin/userActions";

const axiosInterceptor = {
  config: (store) => {
    // Request interceptor
    axios.interceptors.request.use(
      function (config) {
        if (!(config && config.noLoader)) {
          store.dispatch(setLoaderDisplay(true));
        }
        const { user } = store.getState();
        if (user && isNotEmpty(user)) {
          config.headers["Authorization"] = `Bearer ${user.access}`;
        }
        config.headers["api-key"] = "6ca72ea05f741259253e8f80db186aa043c43b51";
        return config;
      },
      function (error) {
        const { config } = error || {},
          { loader } = store.getState();
        if (!(config && config.noLoader) && loader) {
          store.dispatch(setLoaderDisplay(false));
        }
        return Promise.reject(error);
      }
    );
    // Response interceptor
    axios.interceptors.response.use(
      function (response) {
        const { config } = response;
        const { loader } = store.getState();
        if (!(config && config.noLoader) && loader) {
          store.dispatch(setLoaderDisplay(false));
        }
        return response;
      },
      function (error) {
        const { config, response } = error || {},
          { loader } = store.getState();
        const { data: { detail }, status } = response;
        if (!(config && config.noLoader) && loader) {
          store.dispatch(setLoaderDisplay(false));
        }

        if (status === 401) {
          store.dispatch(resetUser());
          store.dispatch(displayNotification("error", detail));
          store.dispatch(resetLoader());
          window.location.replace('/');
        }
        store.dispatch(
          displayNotification("error", "Something went wrong")
        );
        return Promise.reject(error);
      }
    );
  },
};

export default axiosInterceptor;
