
import { useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Spin } from "antd";
import { pdfjs } from 'react-pdf';

import AppHeader from "components/AppHeader";
import UserSignUpComponent from "components/UserSignUp";
import UserLoginComponent from "components/UserLogin";
// import HomePageComponent from "components/HomePage";
import HomePageComponent from "components/HomePage/newHomePage";
import ListPageComponent from "components/ListComponent";
import PdfViewer from "components/PdfViewer/newViewer";
import UserAlerts from "components/UserAlerts";

import { isNotEmpty } from "utils/helper";

import "./App.scss";

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   'pdfjs-dist/build/pdf.worker.min.mjs',
//   import.meta.url,
// ).toString();

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

function App() {
  const store = useSelector((state) => state);
  const { loader, notification } = store;

  return (
    <div className="App">
      <BrowserRouter>
        <UserAlerts notification={notification} />
        <AppHeader showUserProfile={isNotEmpty(store.user)} />
        <div className="container">
          <Spin className="ant-spinner" spinning={loader?.show > 0}>
            <Routes>
              <Route path="/" element={isNotEmpty(store.user) ? <HomePageComponent /> : <UserLoginComponent />} />
              <Route path="/signup" element={<UserSignUpComponent />} />
              <Route path="/:id/view" element={<PdfViewer />} />
              <Route path="/previous_uploads" element={<ListPageComponent />} />
            </Routes>
          </Spin>
        </div>
      </BrowserRouter>

    </div>
  );
}

export default App;
