export const haderConfig = {
  getJsonApp() {
    return {
      "Content-Type": "application/Json"
    };
  },
  getArrayBuffer() {
    return {
      "Content-Type": "arraybuffer"
    };
  },
  getFormData() {
    return {
      "Content-Type": "multipart/form-data"
    };
  }
}