import { Button } from "antd";
import ImageComponent from "components/ImageComponent";
import PropTypes from "prop-types";
// import "./button-component.scss";

const ButtonComponent = ({
    styleName,
    text,
    dataQA,
    defaultImage,
    hoverImage,
    ...props
  }) => {
  return (
    <div data-qa={dataQA} className={`ms-button ${styleName}`}>
      <Button {...props}>
        <figure className="swap-on-hover">
          {defaultImage && (
            <ImageComponent
              imageName={defaultImage.imageName}
              imageAlt={defaultImage.imageAlt}
              imageClassName={`${defaultImage.imageClass} default-image swap-on-hover__front-image`}
            />
          )}
          {hoverImage && (
            <ImageComponent
              imageName={hoverImage.imageName}
              imageAlt={hoverImage.imageAlt}
              imageClassName={`${hoverImage.imageClass} hover-image swap-on-hover__back-image`}
            />
          )}
          <span>{text}</span>
          {props.children}
        </figure>
      </Button>
    </div>
  );
};

ButtonComponent.defaultProps = {
  styleName: ""
};

ButtonComponent.propTypes = {
  dataQA: PropTypes.string.isRequired,
  styleName: PropTypes.string,
  shape: PropTypes.string,
  disabled: PropTypes.bool,
  text: PropTypes.string,
  onClick: PropTypes.func,
  defaultImage: PropTypes.object,
  hoverImage: PropTypes.object
};

export default ButtonComponent;
