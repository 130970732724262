import { useState } from "react";
import { useDispatch } from "react-redux";
import { Card, Input } from "antd";

import ButtonComponent from "components/ButtonComponent";
import { userActions } from "components/UserSignUp/api";
import { setUserDetails } from "./userActions";
import { displayNotification } from "app/appAction";

import "./index.scss";

const UserLoginComponent = (params) => {
  const dispatch = useDispatch();
  const [phone, setPhone] = useState();
  const [password, setPassword] = useState();

  const handlePhoneChange = (e) => {
    e.target.value?.length <= 10 && setPhone(e.target.value);
  };

  const handlepasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const validateCredentials = () => {
    const mobileNumberRegex = /^[2-9]{1}[0-9]{2}[2-9]{1}[0-9]{6}$/;
    const isValidMobile = mobileNumberRegex.test(phone);
    return isValidMobile && !!password;
  };

  const handleLogin = () => {
    if (validateCredentials()) {
      const params = {
        phone,
        password
      }
      userActions.login(params)
        .then(response => {
          const { data } = response;
          dispatch(setUserDetails(data));
          dispatch(displayNotification("success", "Success", "Login successfully"))
        }).catch(error => {
          const { message } = error;
          dispatch(displayNotification("error", "Error", message));
          console.log(error);
        });
    } else {
      console.log("Invalid Email or password");
      dispatch(displayNotification("error", "Error", "Selected Invalid file format"));
    }
  };

  return (
    <div className="container">
      <Card title="Log In to Your Account" className="login-card">
        <div className="login-input">
          <Input
            type="number"
            size="large"
            maxLength={10}
            placeholder="Enter Your Phone"
            onChange={handlePhoneChange}
            controls={false}
            value={phone}
          />
        </div>
        <div className="login-input">
          <Input.Password size="large" value={password} placeholder="Enter Your Password" onChange={handlepasswordChange} />
        </div>
        <div className="submit-btn">
          <ButtonComponent
            dataQA="upload-file"
            className="login-button"
            text="Login"
            onClick={handleLogin}
            disabled={!phone || !password}
          />
        </div>
        <div className="register-link">
          <a href="/signup">
            Click here to register!
          </a>
        </div>
      </Card>
    </div>
  );
};

export default UserLoginComponent;