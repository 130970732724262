const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";
const HIDE_NOTIFICATION = "HIDE_NOTIFICATION";
const INCREMENT_LOADER_COUNT = "INCREMENT_LOADER_COUNT";
const DECREMENT_LOADER_COUNT = "DECREMENT_LOADER_COUNT";
const RESET_LOADER = "RESET_LOADER";

const initialState = {
  type: "info",
  title: "",
  message: "",
  showNotification: false,
  autoCloseDuration: 3000
};

export const notification = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_NOTIFICATION:
      return { ...state, ...action.payload };
    case HIDE_NOTIFICATION:
      return { ...initialState };
    default:
      return state;
  }
};

const loaderInitialState = {
  show: 0,
};

export const loader = (state = loaderInitialState, action) => {
  const { type } = action;
  switch (type) {
    case INCREMENT_LOADER_COUNT:
      return { ...state, show: state.show + 1 };
    case DECREMENT_LOADER_COUNT:
      return { ...state, show: state.show > 0 ? state.show - 1 : 0 };
    case RESET_LOADER:
      return { ...loaderInitialState };
    default:
      return state;
  }
};
