import { useEffect } from "react";
import { Alert } from "antd";
import PropTypes from "prop-types";
import { hideNotification } from "app/appAction";

import "./notification.scss";

const UserAlerts = ({
  notification
}) => {
  const {
    type,
    title,
    message,
    showNotification,
    autoCloseDuration
  } = notification;

  const renderList = () => {
    return message.map((each_message, index) => (
      <li key={index}>{each_message}</li>
    ));
  };

  const descriptionElement = () => {
    if (Array.isArray(message) && message.length > 1) {
      return <ol className="error_list">{renderList()}</ol>;
    }
    return message;
  };

  const renderNotification = () => {
    if (type === "success") {
      setTimeout(hideNotification, autoCloseDuration);
    }

    return (
      <div data-qa="app-notification" className="ms-alert">
        <Alert
          className={"shadow"}
          type={type}
          message={title}
          description={descriptionElement()}
          banner
          closable={true}
          onClose={hideNotification}
        />
      </div>
    );
  };

  useEffect(() => {
    hideNotification();
  }, [hideNotification]);

  return showNotification ? renderNotification() : null;
};

UserAlerts.defaultProps = {
  type: "info"
};

UserAlerts.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.any,
  showNotification: PropTypes.bool,
  hideNotification: PropTypes.func,
  autoCloseDuration: PropTypes.number
};

export default UserAlerts;
