export const USER_DETAILS = "USER_DETAILS";
export const RESET_USER = "RESET_USER";

export const setUserDetails = user => {
  return {
    type: USER_DETAILS,
    user
  };
};

export const resetUser = (user = null) => {
  return {
    type: RESET_USER,
    user
  };
};
