import propTypes from "prop-types";

const ImageComponent = ({ imageName, imageAlt, imageClassName, disabled, disabledImage }) => {
  return (
    <img
      alt={imageAlt}
      className={imageClassName}
      // eslint-disable-next-line
      // src={require(`assets/images/${disabled ? disabledImage : imageName}`)}
    />
  );
};

ImageComponent.propTypes = {
  imageName: propTypes.string.isRequired,
  imageAlt: propTypes.string,
  imageClassName: propTypes.string
};

ImageComponent.defaultProps = {
  imageAlt: "",
  imageClassName: "default-image-class"
};

export default ImageComponent;
