export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";
export const HIDE_NOTIFICATION = "HIDE_NOTIFICATION";
export const INCREMENT_LOADER_COUNT = "INCREMENT_LOADER_COUNT";
export const DECREMENT_LOADER_COUNT = "DECREMENT_LOADER_COUNT";
export const RESET_LOADER = "RESET_LOADER";

export const displayNotification = (type, title, message, autoCloseDuration = 3000) => {
  return {
    type: SHOW_NOTIFICATION,
    payload: {
      type,
      title,
      message,
      showNotification: true,
      autoCloseDuration
    }
  };
};

export const hideNotification = (user = null) => {
  return {
    type: HIDE_NOTIFICATION
  };
};

export const setLoaderDisplay = (showLoader) => {
  return {
    type: showLoader ? INCREMENT_LOADER_COUNT : DECREMENT_LOADER_COUNT,
  };
};

export const resetLoader = () => {
  return {
    type: RESET_LOADER
  };
};

