import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import App from './app/App';
import reportWebVitals from './reportWebVitals';
import store from 'app/configureStore';
import axiosInterceptor from 'utils/axiosInterceptor';

import './index.css';

axiosInterceptor.config(store.store)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store.store}>
    <PersistGate loading={null} persistor={store.persistor}>
      <App />
    </PersistGate>
  </Provider>
);

reportWebVitals();
