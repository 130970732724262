import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, Layout } from 'antd';
import { ArrowLeftOutlined } from "@ant-design/icons";

import UserInfo from 'components/userInfo';
import { userActions } from 'components/UserSignUp/api';
import { resetUser } from 'components/UserLogin/userActions';

import "./app-header.scss";

const { Header } = Layout;

const AppHeader = ({ showUserProfile }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useSelector(state => state);

  const showbackBtn = location.pathname.includes("/view") || location.pathname.includes("/previous_uploads");

  const logout = () => {
    userActions.logout().then(() => {
      dispatch(resetUser());
      navigate("/");
    });
  };

  const handleBackBtnClick = () => {
    if(location.pathname.includes("/previous_uploads")) {
      navigate("/");
    } else navigate("/previous_uploads");
  };

  const navigateToPrevUploads = () => {
    navigate("/previous_uploads");
  };

  return (
    <Header className="header-container">
      {showbackBtn &&
        <Button className="back-btn" onClick={handleBackBtnClick}>
          <ArrowLeftOutlined />
        </Button>
      }
      <div className="header-title">PDF ANALYSER</div>
      {showUserProfile &&
        <div className="user-info">
          <UserInfo logout={logout} user={user} navigateToPrevUploads={navigateToPrevUploads} />
        </div>
      }
    </Header>
  )
}

export default AppHeader;