import { USER_DETAILS, RESET_USER } from "./userActions";

const user = (state = {}, action) => {
  switch (action.type) {
    case USER_DETAILS:
      return action.user;
    case RESET_USER:
      return {};
    default:
      return state;
  }
};

export default user;
