import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Card, Button } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import { reviewRequestApi } from 'components/HomePage/api';
import { setReviewRequestData } from "components/HomePage/ReviewRequestAction";
import './index.scss';

const { Meta } = Card;

const ListPageComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { requestData, next, prev } = useSelector(state => state.reviewRequestReducer);

  const [currentPage, setCurrentPage] = useState(1);

  const fetchReviewRequestData = (url = "") => {
    reviewRequestApi.getReviewRequestList({ url }).then(response => {
      const { data } = response;
      dispatch(setReviewRequestData(data));
    }).catch(error => {
      console.log(error);
    });
  };

  const handlePrevBtnClick = () => {
    if (prev) {
      fetchReviewRequestData(prev);
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextBtnClick = () => {
    if (next) {
      fetchReviewRequestData(next);
      setCurrentPage(currentPage + 1)
    }
  };

  const handleOpenCardClick = (fileInfo) => {
    const { id } = fileInfo;
    navigate(`/${id}/view`);
  };

  useEffect(() => {
    fetchReviewRequestData();
  }, []);

  return (
    <div className="container">
      <div className="card-container">
        {requestData?.map(item => (
          <Card
            key={item.id}
            className="custom-card"
            onClick={() => handleOpenCardClick(item)}
            cover={<img alt="pdf" src={require("assets/images/samplePdf.png")} />}
          >
            <Meta
              title={item?.document_name}
              description={`Upload Date: ${moment(item.created_at).format("DD-MM-YYYY")}`}
            />
          </Card>
        ))}
      </div>
      <div className="pagination">
        <Button className="pagination-btn" onClick={handlePrevBtnClick} disabled={!prev}>
          <LeftOutlined /> Prev
        </Button>
        <div className="page-number">{currentPage}</div>
        <Button className="pagination-btn" onClick={handleNextBtnClick} disabled={!next}>
          Next <RightOutlined />
        </Button>
      </div>
    </div>
  )
};

export default ListPageComponent;