'use client'

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Input } from 'antd';
import { Upload, FileText, BarChart2 } from 'lucide-react'

import { Card, CardContent, CardHeader, CardTitle } from "../ui/card"
import { defaultUploadFileTypes } from "utils/constants";
import { displayNotification } from 'app/appAction'
import { reviewRequestApi } from './api'

import './home_page.scss';

const HomePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector(state => state);

  const [file, setFile] = useState(null)
  const [error, setError] = useState(null)

  const validateFile = (file) => {
    if (!file) return false;
    const fileName = file?.name;
    const splitName = fileName?.split(".");
    const extension = splitName[splitName?.length - 1];
    return defaultUploadFileTypes.includes(extension);
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files?.[0]
    if (selectedFile) {
      if (selectedFile.type === 'application/pdf') {
        setFile(selectedFile)
        setError(null)
      } else {
        setFile(null)
        setError('Please upload a valid PDF file.')
        dispatch(displayNotification("error", "Error", "Please upload a valid PDF file."))
      }
    }
  }

  const handlePdfUpload = () => {
    const isValidFile = validateFile(file);
    if (file && isValidFile) {
      const formData = new FormData();
      formData.append("document", file);
      formData.append("reviewer", user?.id);

      reviewRequestApi.uploadPdf(formData)
        .then(response => {
          const { data } = response;
          console.log(data);
          const { id } = data;
          navigate(`/${id}/view`);
          dispatch(displayNotification("success", "Success", "File Uploaded Successfully"))
        })
    } else {
      dispatch(displayNotification("error", "Error", "Please upload a valid PDF file."))
    }
  };


  return (
    <main className="home-page-container">
      <section className="info-text-top">
        <h2 className="">Check Your PDF Formatting</h2>
        <p className="">FormatCheck helps ensure your PDFs meet professional standards.</p>
      </section>

      <Card className="upload-file-card">
        <CardHeader className="card-header">
          <CardTitle className="">Analyze Your PDF</CardTitle>
          <p>Upload your document for formatting feedback.</p>
        </CardHeader>
        <CardContent>
          <div>
            <p htmlFor="pdf-upload" className="">
              Select PDF File
            </p>
            <div className="upload-file">
              <Input
                id="pdf-upload"
                type="file"
                accept=".pdf"
                onChange={handleFileChange}
                className="flex-1 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
              />
              <Button onClick={handlePdfUpload} className="upload-btn">
                <Upload className="" /> Check
              </Button>
            </div>
            <p className="error-text">{error}</p>
          </div>
        </CardContent>
      </Card>

      <section className="format-info-card">
        <h2>How FormatCheck Works</h2>
        <div className="steps-wrap">
          <div className="text-center">
            <div className="upload">
              <Upload />
            </div>
            <h3 className="text-gray-dark">1. Upload</h3>
            <p className="text-gray-medium">Upload your PDF document to our platform.</p>
          </div>
          <div className="text-center">
            <div className="analyze">
              <BarChart2 />
            </div>
            <h3 className="text-gray-dark">2. Analyze</h3>
            <p className="text-gray-medium">We check your document's formatting.</p>
          </div>
          <div className="text-center">
            <div className="review">
              <FileText />
            </div>
            <h3 className="text-gray-dark">3. Review</h3>
            <p className="text-gray-medium">Get a report with formatting suggestions.</p>
          </div>
        </div>
      </section>

      <section>
        <h2 className="text-gray-dark">Ready to Check Your Document?</h2>
        <p className="text-gray-medium">Ensure your PDFs are properly formatted with FormatCheck.</p>
      </section>
    </main>

  )
}

export default HomePage;