import axios from "axios"
import { API_HOST } from "utils/constants";
import { haderConfig } from "utils/headerConfig";

const headerConfigJson = haderConfig.getJsonApp();

export const userActions = {
  signUp(params = {}) {
    return axios({
      url: `${API_HOST}/api/v1/users/register/`,
      method: "post",
      data: params,
      headers: headerConfigJson
    })
  },
  login(params = {}) {
    return axios({
      url: `${API_HOST}/api/v1/users/login/`,
      method: "post",
      data: params,
      headers: headerConfigJson
    })
  },
  logout() {
    return axios({
      url: `${API_HOST}/users/logout/`,
      method: "post",
      headers: headerConfigJson
    });
  },
};