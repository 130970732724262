import axios from "axios"
import { API_HOST } from "utils/constants";
import { haderConfig } from "utils/headerConfig";

export const reviewRequestApi = {
  uploadPdf(params = {}) {
    return axios({
      url: `${API_HOST}/api/v1/review-requests/`,
      method: "post",
      data: params,
      headers: haderConfig.getFormData()
    })
  },
  getReviewRequestList(params = {}) {
    return axios({
      url: params?.url || `${API_HOST}/api/v1/review-requests/`,
      method: "get",
      headers: haderConfig.getJsonApp()
    })
  },
  getReviewRequestData(params = {}) {
    return axios({
      url: `${API_HOST}/api/v1/review-requests/${params.id}/results`,
      params,
      method: "get",
      headers: haderConfig.getJsonApp()
    });
  }
};