import { SET_REVIEW_REQUEST_DATA } from "./ReviewRequestAction";

const initialState = {
  count: 0,
  requestData: []
}
const reviewRequestReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_REVIEW_REQUEST_DATA:
      return {
        ...state,
        count: payload.count,
        next: payload.next,
        prev: payload.previous,
        requestData: payload.results
      }
    default:
      return state;
  };
};

export default reviewRequestReducer;
