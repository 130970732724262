import { combineReducers } from "redux";
import { RESET_USER } from "components/UserLogin/userActions";
import {
  notification,
  loader
} from "./appReducer";
import user from "components/UserLogin/userReducer";
import reviewRequestReducer from "components/HomePage/reviewRequestReducer";

const applicationReducer = combineReducers({
  //here all the reducers of the project
  user,
  notification,
  loader,
  reviewRequestReducer
});

const rootReducer = (state, action) => {
  if (action.type === RESET_USER) {
    // for all keys defined in your persistConfig(s)
    // storage.removeItem("persist:root");
    // storage.removeItem("persist:reduxStore");

    state = undefined;
  }
  return applicationReducer(state, action);
};

export default rootReducer;
