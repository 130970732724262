import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Card, Input } from "antd";

import ButtonComponent from "components/ButtonComponent";
import { userActions } from "./api";
import { displayNotification } from "app/appAction";
import { setUserDetails } from "components/UserLogin/userActions";

import "./sign-up.scss";

const UserSignUpComponent = (params) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [password1, setPassword1] = useState();
  const [password2, setPassword2] = useState();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePhoneChange = (e) => {
    e.target.value?.length <= 10 && setPhone(e.target.value);
  };

  const handlepassword1Change = (e) => {
    setPassword1(e.target.value);
  };

  const handlepassword2Change = (e) => {
    setPassword2(e.target.value);
  };

  const disableSubmitBtn = useCallback(() => {
    return !(email && phone && password1 && password2);
  }, [email, phone, password1, password2]);

  const validateCredentials = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(email);
    const mobileNumberRegex = /^[2-9]{1}[0-9]{2}[2-9]{1}[0-9]{6}$/;
    const isValidMobile = mobileNumberRegex.test(phone);
    const isEqualPassword = password1 === password2;
    return isValidEmail && isValidMobile && !!password1 && !!password2 && isEqualPassword;
  };

  const handleSignUp = () => {
    if (validateCredentials()) {
      const params = {
        phone,
        email,
        password1,
        password2
      }
      userActions.signUp(params).then((response) => {
        console.log(response);
        const { data } = response;
        dispatch(setUserDetails(data));
        navigate("/");
      }).catch((error) => {
        console.log(error)
      })
    } else {
      dispatch(displayNotification("error", "Error", "One of the field is invalid"))
    }
  };

  return (
    <Card title="Create Your Account" className="signup-card">
      <div className="signup-input">
        <Input
          type="number"
          size="large"
          maxLength={10}
          placeholder="Enter Your Phone"
          onChange={handlePhoneChange}
          controls={false}
          value={phone}
        />
      </div>
      <div className="signup-input">
        <Input size="large" placeholder="Enter Your Email" onChange={handleEmailChange} />
      </div>
      <div className="signup-input">
        <Input.Password size="large" value={password1} placeholder="Enter Your Password" onChange={handlepassword1Change} />
      </div>
      <div className="signup-input">
        <Input.Password size="large" value={password2} placeholder="Re-Enter Your Password" onChange={handlepassword2Change} />
      </div>
      <div className="submit-btn">
        <ButtonComponent
          dataQA="upload-file"
          className="login-button"
          text="Sign Up"
          onClick={handleSignUp}
          disabled={disableSubmitBtn()}
        />
      </div>
      <div className="login-link">
        <a href="/">
          Have an account? Sign in now.
        </a>
      </div>
    </Card>
  );
}

export default UserSignUpComponent;