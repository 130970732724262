import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Document, Page } from "react-pdf";
import { Button } from "antd";
import { ChevronLeft, ChevronRight, AlertCircle, Download } from "lucide-react";
import { Card, CardContent, CardHeader, CardTitle } from "../ui/card";
import { ScrollArea, Separator } from "../ui/scrollArea";
import { reviewRequestApi } from 'components/HomePage/api';

import "./newView.scss";

const PdfViewer = () => {
  const { id } = useParams();
  const [outPutFile, setOutPutFile] = useState(null);
  const [resultNumPages, setResultNumPages] = useState(null);
  const [resultPageNumber, setResultPageNumber] = useState(1);
  const [errorDetails, setErrorDetails] = useState([]);

  const currentPageErrors = useMemo(() => {
    return resultPageNumber && errorDetails[resultPageNumber - 1] ? errorDetails[resultPageNumber - 1] : undefined;
  }, [resultPageNumber, errorDetails]);

  const onResultDocumentLoadSuccess = ({ numPages }) => {
    setResultNumPages(numPages);
  };

  const goToResultPrevPage = () => {
    setResultPageNumber(resultPageNumber - 1);
  };

  const goToNextResultPage = () => {
    setResultPageNumber(resultPageNumber + 1);
  }

  const parseErrorData = (results, errorPages) => {
    const errorArray = results.filter(result => errorPages.includes(result.page_number))
      .map(result => ({
        ...result.details,
        pageNumber: result.page_number
      })).sort((a, b) => a.pageNumber - b.pageNumber);
    setErrorDetails(errorArray);
  };

  const getErrorMessage = (error) => {
    return (
      <div>
        <h3 className="issue-text">Page Number: {error?.pageNumber}</h3>
        <ul>
          {Object.entries(error)
            .filter(([key]) => key !== "pageNumber") // Exclude the "page" key
            .map(([_, message], index) => (
              <li key={index}>{message}</li>
            ))}
        </ul>
      </div>
    )
  };

  const downloadPDF = () => {
    const url = outPutFile;

    // Create a temporary anchor element
    const link = document.createElement('a');
    link.href = url;
    link.download = 'filename.pdf'; // Set the desired file name for the download

    // This forces the browser to download the file instead of opening it
    link.target = '_blank';

    // Append the link to the document, trigger the click, and then remove the element
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    const params = { id, flaged: 1 };
    reviewRequestApi.getReviewRequestData(params).then(response => {
      const { data } = response;
      const { results, review_request, error_pages = [] } = data;
      setOutPutFile(review_request.output);
      parseErrorData(results, error_pages);
      setResultNumPages(1)
    })
  }, [id]);

  return (
    <div className="pdf-analysis-container">
      <div className="view-card-container">
        <div className="issues-column">
          <Card className="issues-card">
            <CardHeader className="card-header">
              <CardTitle>All Page Issues</CardTitle>
            </CardHeader>
            <CardContent className="card-content">
              <ScrollArea className="scroll-area">
                <ul className="issues-list">
                  {errorDetails.map((error) => (
                    <li key={error.pageNumber} className="issue-item">
                      <div className="issue-content">
                        <AlertCircle className="issue-icon" />
                        {getErrorMessage(error)}
                      </div>
                    </li>
                  ))}
                </ul>
              </ScrollArea>
            </CardContent>
          </Card>
          {currentPageErrors &&
            <Card className="secondary-issues-card">
              <CardHeader className="secondary-issues-card-header">
                <CardTitle>Current Page Issues</CardTitle>
              </CardHeader>
              <CardContent className="secondary-issues-card-content">
                <ScrollArea className="curren-issue-scroll-area">
                  <ul className="issues-list">
                    <li className="issue-item">
                      <div className="issue-content">
                        <AlertCircle className="issue-icon" />
                        {getErrorMessage(currentPageErrors)}
                      </div>
                    </li>
                  </ul>
                </ScrollArea>
              </CardContent>
            </Card>
          }
        </div>

        <div className="pdf-view-wrap">
          <Card className="pdf-preview-card">
            <CardHeader className="pdf-card-header">
              <CardTitle>Page Preview</CardTitle>
            </CardHeader>
            <CardContent className="card-content">
              <div className="pdf-viewer">
                <Document file={{ url: outPutFile }} onLoadSuccess={onResultDocumentLoadSuccess} className="document">
                  <Page pageNumber={resultPageNumber} renderTextLayer={false} renderAnnotationLayer={false} />
                </Document>
              </div>
              <Separator className="separator" />
              <div className="pagination">
                <Button disabled={resultPageNumber <= 1} onClick={goToResultPrevPage} className="pagination-button">
                  <ChevronLeft className="icon" /> Previous
                </Button>
                <div className="page-info">
                  <span className="page-number">Page {resultPageNumber} of {resultNumPages}</span>
                  <Button
                    onClick={downloadPDF}
                    variant="outline"
                    size="sm"
                    className="download-btn"
                  >
                    <Download /> Download Corrections
                  </Button>
                </div>
                <Button disabled={resultPageNumber >= resultNumPages} onClick={goToNextResultPage} className="pagination-button">
                  Next <ChevronRight className="icon" />
                </Button>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  )
}

export default PdfViewer;